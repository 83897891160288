.navbar-header .custom-search-form .customer-search-query {
  width: 400px;
  top: 13px;
  left: 0px;
  border-color: rgba(158, 151, 151, 0);
  box-shadow: none;
  opacity: 1;
  background: #FBFBFB ;
  height: 34px;
}

.navbar-header .custom-search-form .search-submit {
  top: 13px;
  background: #E8E8E8;
  border-color: #E8E8E8;
}

.navbar-header .custom-search-form .search-submit:hover {
  color: #1ab394;
}

.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, .1);

  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 254px;
  overflow: auto;

}

.autocomplete-suggestion {
  position: relative;
  padding: 0 .6em;
  line-height: 23px;
  
  overflow: visible;
  font-size: 1.02em;
  
  color: #333;
  width: auto;
  padding: 10px;
}

.autocomplete-suggestion b {
  font-weight: normal;
  color: #1f8dd6;
}

.autocomplete-suggestion.selected {
  background: #f0f0f0;
}