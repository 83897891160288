// Bootstrap Select // ------------------------------------------------

.bootstrap-select.btn-group {
    @media (max-width: @screen-xs-max) {
        margin-bottom: 0 !important;
        width: 100% !important;
    }
}

.bootstrap-select .dropdown-toggle {
    background-color: #FFF;
    border: 1px solid @input-border;
    .box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
    font-weight: 400;
    font-family: @font-family-sans-serif;
    height: 36px;
    color: #555;
    &:active,
    &:focus,
    &:hover {
        color: #555;
        border-color: #CCC;
    }
}


.dropdown-menu.open {
    @media (max-width: @screen-xs-max) {
        width: 100% !important;
    }
}
