.wizard_complete()
{
    background-color : #97C463;
    .img-retina('../../images/icons/wizard-tick.png', '../../images/icons/wizard-tick@2x.png', 14px, 14px);
}

.wizard {
    background    : #D7D8DA;
    height        : 10px;
    position      : relative;
    border-radius : 100px;
    margin        : 24px 15px 0 0px;

    @media (max-width : @screen-sm-max) {
        margin : 20px 0 0;
    }
    @media (max-width : @screen-xs-max) {
        margin : 60px 0 30px;
    }

    .step {
        background    : #D7D8DA scroll no-repeat center center;
        border-radius : 100px;
        position      : absolute;
        top           : -8px;
        .square(25px);
    }

    .step-text {
        color       : @blue;
        text-align  : center;
        position    : absolute;
        left        : 50%;
        margin-left : -25px;
        bottom      : 28px;
        width       : 50px;
    }

    /* Progress Bar */
    .bar {
        background-color : #97C463;
        height           : 100%;
        border-radius    : 100px;
    }

    /* Positions */
    .step.one { left : 0; .wizard_complete() }
    .step.two { left : 50%; margin-left : -15px; }
    .step.three { right : 0; }

}

/* Progress Sizing */
.wizard__open, .wizard__lapsed {
    .bar {
        width : 0%;
    }

    &.is-summary {
        .two {
            .wizard_complete;
        }
        .bar {
            width: 50%;
        }
    }
}

.wizard__processing_payment,
.wizard__unpaid,
.wizard__completed {
    .two,
    .three {
        .wizard_complete;
    }
    .bar {
        width : 100%;
    }
}