.clickable-row {
    cursor: pointer;
}

#js-account-search {
    height: 32px;
    padding: 0;
}

.panel-filters {
    display: inline-block;
}

.panel-filters .form-group {
  display: inline-block;
}

.btn-list {
    border: 0 none;
    margin: 0;
    padding: 10px;
}

.tab-content .dropdown-menu {
    color: #333333;
}

.payment-card .payment-icon-big {
    font-size: 60px;
}

.payment-direct-debit {
    width: 80px;
}

@keyframes rotate {
    from {
        .rotate(0deg);
    }
    to {
        .rotate(360deg);
    }
}

.is-rotating {
    .animation(rotate 1s linear infinite);
}


// Sorry
.picker__input[disabled],
.picker__input[readonly] {
    background-color : #FFF;
    cursor : pointer;
    width : 200px;
}