
.ibox-content {
    padding : 15px 20px 15px 20px;
}

.navbar {
    margin-bottom : 0;
}

.order-item {
    ul {
        padding : 0;
    }
    li {
        list-style : none;
    }
}

.nav-header {
    padding    : 15px 25px;
    background : url("../../images/header-profile.png") no-repeat;
}

.logged-in-user {
    color      : #DFE4ED;
    margin-top : 18px;
}

.customer-info {
    font-size : 14px;
}

.customer-info__breadcrumb {
    display : inline;
}

.customer-info__meta {
    padding : 5px 15px;
}

.order-item__actions {
    float      : right;
    margin-top : -2px;
}

.summary__payment-method {
    display : inline-block;
}

.bs-callout {
    padding           : 20px;
    margin            : 20px 0;
    border            : 1px solid #EEE;
    border-left-width : 5px;
    border-radius     : 3px;
}

.bs-callout h4 {
    margin-top    : 0;
    margin-bottom : 5px;
}

.bs-callout p:last-child {
    margin-bottom : 0;
}

.bs-callout code {
    border-radius : 3px;
}

.bs-callout + .bs-callout {
    margin-top : -5px;
}

.bs-callout-default {
    border-left-color : #777;
}

.bs-callout-default h4 {
    color : #777;
}

.bs-callout-primary {
    border-left-color : #428BCA;
}

.bs-callout-primary h4 {
    color : #428BCA;
}

.bs-callout-success {
    border-left-color : #5CB85C;
}

.bs-callout-success h4 {
    color : #5CB85C;
}

.bs-callout-danger {
    border-left-color : #D9534F;
}

.bs-callout-danger h4 {
    color : #D9534F;
}

.bs-callout-warning {
    border-left-color : #F0AD4E;
}

.bs-callout-warning h4 {
    color : #F0AD4E;
}

.bs-callout-info {
    border-left-color : #5BC0DE;
}

.bs-callout-info h4 {
    color : #5BC0DE;
}

.app-title {
    color     : #FFF;
    font-size : 18px;
    display   : inline-block;
}

.logo {
    width        : 40px;
    margin-top   : -6px;
    margin-right : 5px;
}

.nav i {
    color : #7EB04C;
}

.status {
    text-transform : capitalize;
}

.void { color : @red; }

.approved, .valid, .collected { color : @navy; }

.pending { color : @yellow; }

.ibox-title h5 {
    color : @lazur;
}


