.alert-environment {
    .clearfix;
    .alert-variant(#FFF, #DDD, #333);
    border-radius: 0;
    border-left: none;
    border-right: none;
    margin-bottom : 0;

    a {
        color: inherit;
    }

    &--training {
        .alert-variant(#61abe6, #61abe6, #FFF);
    }
    &--staging {
        .alert-variant(#7EB04C, #7EB04C, #FFF);
    }
}

.alert-environment__title {
    float: left;
}

.alert-environment__link {
    float: right;
    margin: 0 !important;
}